.search-bar {
    background-color: rgb(255, 255, 255);
}

.search-bar-container .search-wrapper {

    will-change: opacity;
}

.search-bar-container .search-wrapper .search-container .search-inner {

    /* z-index: 2; */
    padding: 10px 0;
    margin: auto;
}

.search-placeholder {

    display: flex;
    align-items: center;
    width: 90%;
    margin: 10px auto;
    border: 1px solid rgb(139, 139, 163);
    border-radius: 12px;
    padding: 0px;
}

.search-icon {
    height: 100%;
    display: flex;
    padding: 12px 8px 12px 12px;
    align-items: center;
    justify-content: center;
}

.search-input {
    border-radius: 0px 12px 12px 0px;
    letter-spacing: 0.0025em;
    box-sizing: border-box;
    flex: 1 1 0%;
    padding: 12px 0px;
    border: none;
    font-family: 'Mier Demi';
    font-size: 14px;
    height: 100%;
    width: 100%;
    caret-color: rgb(159, 32, 137);
}

.search-input::placeholder {
    color: #adb5bd;
    /* Placeholder text color */
}

.jMfCEJ {
    height: 1px;
    background-color: rgb(206, 206, 222);
    border-radius: 2px;
    width: 100%;
}