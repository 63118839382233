.gqSLnX {
    width: 100%;
    max-width: 500px;
    margin: auto;
    height: 56px;
    background-color: #ffffff;
    font-family: 'Mier Book';
}.chtKwW {
    width: 100%;
    min-width: 300px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    padding: 0px 8px;
    margin-top: 10px;
}.eOmvaT {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
}.hGoFZP {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 25%;
    gap: 6px;
    max-width: 200px;
}.geeMAN {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
}.lagJzQ {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(85, 133, 248);
    visibility: hidden;
}.bSausD {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(206, 206, 222);
    visibility: visible;
}.ezBHwi {
    font-size: 11px;
    color: rgb(206, 206, 222);
}.cRaGaC {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgb(85, 133, 248);
    color: rgb(85, 133, 248);
    font-size: 11px;
    font-weight: 700;
}.kHHhBS {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(206, 206, 222);
    visibility: visible;
}.iefUco {
    font-size: 11px;
    color: rgb(53, 53, 67);
}

.iefbLi {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgb(206, 206, 222);
    color: rgb(206, 206, 222);
    font-size: 11px;
    font-weight: 700;
}.jSyZxf {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(206, 206, 222);
    visibility: hidden;
}.bbBFRm {
    overflow: auto;
    background-color: rgb(234, 234, 242);
    width: 100%;
    position: fixed;
    bottom: 69px;
    top: 112px;
    max-width: 800px;
}

.izYblt {
    margin-bottom: 8px;
    background-color: rgb(255, 255, 255);
    padding: 20px 16px;
}

.lcYQRo {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 5px;
    font-family: 'Mier Book';
    gap: 8px;
}
/* Form Css */
.bbBFRm {
    overflow: auto;
    background-color: rgb(234, 234, 242);
    width: 100%;
    position: fixed;
    bottom: 69px;
    top: 112px;
    max-width: 800px;
}.izYblt {
    margin-bottom: 8px;
    background-color: rgb(255, 255, 255);
    padding: 20px 16px;
}.lcYQRo {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
}.iZWODx {
    display: flex;
}.gANwSd {
    color: rgb(53, 53, 67);
    font-weight: 700;
    font-size: 17px;
    font-family: "Mier Book";
    -webkit-box-flex: 1;
    flex-grow: 1;
}.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}.form-floating .form-control, .form-floating .form-select {
    font-size: 14px !important;
    border: 1px solid rgba(0, 0, 0, .24);
    height: 50px !important;
    min-height: auto;
    line-height: initial;
    border-radius: 12px;
}
.form-floating>.form-control, .form-floating>.form-select, .form-floating>.form-control-plaintext {
    padding: 10px 12px !important;
}.form-floating>label {
    font-size: 14px;
    font-family: Roboto, Droid Sans, Helvetica Neue, Arial, Helvetica, sans-serif;
    color: #878787;
    height: auto !important;
    padding: 14px;
}
.form-floating>label {
    position: absolute;
    top: 0;
    left: auto;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}
/* button css */
.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}
.card-footer {
    position: fixed;
    width: 95%;
    max-width: 800px;
    background-color: rgb(255, 255, 255);
    bottom: 0px;
    z-index: 1;
    right: 50%;
    transform: translate(50%, 0px);
}[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.save-address {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.0015em;
    font-size: 15px;
    line-height: 20px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(159, 32, 137);
    border: none;
    padding: 12px;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
}.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}.form-floating {
    position: relative;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}
.row>* {
 
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}.row .form-floating>label {
    left: 8px !important;
}.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    transform: scale(.85) translateY(-.7rem) translateX(1.15rem) !important;
    background-color: #fff;
    padding: 0rem 0.75rem;
}