.header-title {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    font-family: "Mier demi";
    margin: 5px 0px 0px 5px;
    padding: 0px;
    text-transform: uppercase;
}

._1FWdmb {
    font-size: 16px;
    color: #eaeaea;
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 52px;
    transition: background-color .3s ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 18px 12px 18px;
    background-color: rgb(255, 255, 255);
}

.gqSLnX {
    width: 100%;
    max-width: 500px;
    margin: auto;
    height: 56px;
    background-color: #ffffff;
}

.chtKwW {
    width: 100%;
    min-width: 300px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    padding: 0px 8px;
    margin-top: 10px;
}

.eOmvaT {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
}

.hGoFZP {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 25%;
    gap: 6px;
    max-width: 200px;
}

.geeMAN {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
}

.lagJzQ {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(85, 133, 248);
    visibility: hidden;
}

.cRaGaC {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgb(85, 133, 248);
    color: rgb(85, 133, 248);
    font-size: 11px;
    font-weight: 700;
}

.bSausD {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(206, 206, 222);
    visibility: visible;
}

.iefUco {
    font-size: 11px;
    color: rgb(53, 53, 67);
}

.kHHhBS {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(206, 206, 222);
    visibility: visible;
}

.iefbLi {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgb(206, 206, 222);
    color: rgb(206, 206, 222);
    font-size: 11px;
    font-weight: 700;
}

.ezBHwi {
    font-size: 11px;
    color: rgb(206, 206, 222);
}

.jSyZxf {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2px;
    background-color: rgb(206, 206, 222);
    visibility: hidden;
}

.cart-list {
    max-height: max-content;
    /* min-height: 30vh; */
}

.cart-products-list {
    /* max-height: 60vh; */
    overflow: scroll;
}

.cart-product {
    border-bottom: 5px solid #eaeaf2;
}

.cart-product {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
    /* border-bottom: 1px solid #eaeaea; */
}

.cart-product-img {
    width: 30%;
    background: #f7f9fa;
    border-radius: 10px;
}

.cart-product-img img {
    width: 100%;
}

.cart-product-details {
    width: 60%;
}

.cart-product-title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5px;
    margin-right: 5px;
}

.cart-product-title p {
    font-size: 14px;
    font-weight: 900;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    padding-right: 25px;
    margin: 0;
}

.cart-product-title img.remove-cart-item {
    width: 16px;
}

.cart-product-pricing {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
}

.cart-product-price {
    font-size: 13px;
    font-weight: 600;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-family: "Mier book";
    white-space: nowrap;
    margin: 0;
}

.cart-product-mrp {
    text-decoration-line: line-through;
    color: #8b8b8b;
    font-size: 14px;
    margin-left: 10px;
}

.cart-product-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart-product-color {
    margin: 0;
    /* padding: 5px 10px; */
    /* background: #f7f9fa; */
    border-radius: 6px;
    font-weight: 600;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-family: "Mier book";
    white-space: nowrap;
    margin-right: 8px;
}

.cart-qty {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-family: "Mier book";
    white-space: nowrap;
    margin-left: 8px;
}

.cart_page_footer {
    box-shadow: none;
    position: unset;
    border-bottom: 5px solid #eaeaf2;
}

.cart__footer {
    /* position: absolute; */
    bottom: 0;
    background: white;
    width: 100%;
    padding: 10px;
    /* box-shadow: -1px 19px 19px 11px #ccc; */
}

.cart__total,
.shipping__total,
.mc_pay__total {
    padding: 10px 0;
}

.cart__total,
.shipping__total,
.mc_pay__total {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 5px 0;
}

.cart__total {
    text-decoration: underline dotted;
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    font-family: "Mier book";
    margin: 0px;
    padding: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.jMfCEJ {
    height: 1px;
    background-color: rgb(206, 206, 222);
    border-radius: 2px;
    width: 100%;
}

.cart__total,
.shipping__total,
.mc_pay__total {
    padding: 10px 0;
}

.mc_pay__total {
    /* padding: 5px 0; */
    color: rgb(53, 53, 67);
    font-weight: 600;
    font-size: 15px;
    font-family: "Mier demi";
    text-align: left;
    line-height: 24px;
}

.sefty-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 15px 0;
}

.lefty {

    display: flex;
    align-items: center;
    gap: 10px;
}.button-containerzz {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -4px 3px 7px #bdbbbb;
    padding: 12px 16px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
}.dUijPM {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 50%;
    padding-right: 8px;
}.dUijPM span {
    color: rgb(53, 53, 67);
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    font-family: "Mier Book";
}.dUijPM button {
    font-weight: unset;
    font-family: "Mier Book";
}.iAFVK {
    width: 50%;
}.iAFVK button {
    width: 100%;
    font-weight: 600;
    font-family: "Mier Book";
}.bwHzRF {
    cursor: pointer;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.0015em;
    font-size: 15px;
    line-height: 20px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(159, 32, 137);
    border: none;
    padding: 12px;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}.ylmAj {
    color: rgb(159, 32, 137);
    cursor: pointer;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.0015em;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 4px;
    background: inherit;
    border: none;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}