.itivvW {
    position: fixed;
    inset: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(51, 51, 51, 0.9);
  }
  
  .ffUKkP {
    display: grid;
    place-items: center;
    position: fixed;
    inset: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #9f208903;
  }
  
  .kIzYUf {
    padding: 17px 15px;
    background: rgb(255, 255, 255);
    border-radius: 8px;
  }
  
  .kxdQNm {
    width: 42px;
    height: 35px;
  }
  
  .dxZalB {
    stroke-width: 5;
    stroke-linecap: round;
    fill: none;
    stroke-dashoffset: 0;
    stroke-dasharray: 125;
    stroke: rgb(217, 166, 208);
  }
  
  .byJEYc {
    stroke-width: 5;
    stroke-linecap: round;
    fill: none;
    stroke: rgb(159, 32, 137);
    animation: 1.4s ease-in 0s infinite normal none running dOFKuy;
    stroke-dasharray: 125;
    stroke-dashoffset: 137;
  }
  
  .lpeJlm {
    animation-delay: 0.3s;
  }
  
  @keyframes dOFKuy {
    0% {
      stroke-dashoffset: 137;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -137;
    }
  }
  