.wDrko {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    font-family: "Mier Demi" !important;
    text-align: left;
    margin: 0px;
    padding: 16px 0px 12px 16px;
}.product-list {
    padding: 0;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    /* column-gap: 10px; */
    /* justify-content: center; */
}
.product-card {
    background-color: #fdfdfdb3;
    padding: 0px;
    width: 50%;
    display: flex;
    /* box-shadow: 0px 0px 11px 0px #e5e5e55c; */
    border: 1px solid #ccc6;
    border-bottom: 2px solid #ccc6;
    flex-flow: column;
    position: relative;
}
.product-card a {
    text-decoration: none;
    color: #333;
}
.product-img {
    height: 240px;
    background-color: #fff;
    width: 100%;
    position: relative;
    border-radius: 6px;
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    overflow: hidden;
}
.product-img img {
    width: auto;
    height: 100%;
}
.product-details {
    width: 100%;
    overflow: auto; /* Allows scrolling */
    padding: 5px 5px 0 5px;
    margin-top: 16px;
}

.product-details::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}

.product-name {
    color: rgb(139, 139, 163);
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    font-family: "Mier demi";
    margin: 0px;
    padding: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: left;
}

.product-price {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.02px;
    line-height: 28px;
    margin: 5px 0px;
}
.sell-price {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    font-family: "Mier Book"!important;
    margin: 0px;
    padding: 0px;
    margin-right: 4px;
}
.line-through {
    text-decoration: line-through;
    text-decoration-line: line-through;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
}
.mrp-price {
    color: rgb(139, 139, 163);
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    font-family: "Mier Demi"!important;
    margin: 0px;
    padding: 0px;
    margin-right: 4px;

}
.off-percentage {
    color: rgb(53, 53, 67);
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    font-family: "Mier Demi"!important;
    margin: 0px;
    padding: 0px;
}
.iMEkWH {
    color: rgb(3, 141, 99);
    background-color: rgb(211, 244, 234);
    border-radius: 48px;
    margin-top: 6px;
    display: flex;
    font-family: 'Mier Book'!important;
    gap: 4px;
    -webkit-box-align: center;
    align-items: center;
    padding: 4px 6px;
    width: max-content;
}
.eqGISc {
    height: 12px;
    width: 12px;
    cursor: inherit;
}
.bjrKWS {
    color: rgb(3, 141, 99);
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    font-family: "Mier Book"!important;
    margin: 0px;
    padding: 0px;
}
.free-delivery {
    pointer-events: none;
    color: rgb(97, 97, 115);
    background: rgb(248, 248, 255);
    padding: 4px 8px;
    border-radius: 48px;
    display: inline-flex;
    color: rgb(97, 97, 115);
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    font-family: "Mier demi"!important;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.jTuxux {
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    padding-bottom: 4px;
}
.heKerA {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}
.jUvjLH {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 16px;
    padding: 3px 6px;
    background: rgb(3, 141, 99);
    fill: rgb(255, 255, 255);
}   
.jrrvJf {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    font-family: "Mier demi";
    margin: 0px;
    padding: 0px;
}
.jZyLzI.jZyLzI {
    padding: 0px 4px;
}
.gTFgDk {
    color: rgb(139, 139, 163);
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    font-family: "Mier book";
    margin: 0px;
    padding: 0px;
}
.products{
    background-color: white;
    padding-bottom: 55px;
}
.noProduct
{
    font-family: 'Mier Demi';
    width: 100%;
    text-align: center;
    padding: 10px;
}
