.check {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    /* gap: 140px; */
    padding: 14px 20px;
}

.left {
    display: flex;
    width: 50%;
    align-items: center;
    gap: 19px;
}
.right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    gap: 17px;
}.navspan {
    margin-left: 0 !important;
    right: 0px !important;
    position: absolute !important;
    background: rgb(255, 231, 251) !important;
    border-radius: 50% !important;
    right: -10px !important;
    top: -7px !important;
    font-size: 10px !important;
    font-family: 'Mier Book' !important;
    font-weight: bold !important;
    color: rgb(159, 32, 137) !important;
    height: 20px !important;
    width: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
